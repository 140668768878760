import { McButton, McLoadingIndicator, McModal, McSelect } from "@maersk-global/mds-react-wrapper";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getAPI } from "../../services/CepApiServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserRole } from "../../utils/token";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";

interface IPROPS {
    isModalOpen?: boolean;
    onCloseFunc?: any;
    onSubmitFunc?: any;
}
const IssueRecapSelectionModal = ({isModalOpen, onCloseFunc, onSubmitFunc} : IPROPS) => {
    const [dropdownValues, setDropdownValues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [isDaySection, setIsDaySection] = useState(false);
    const [userRole, setUserRole] = useState<any>("");
    const [voDayField, setVoDayField] = useState<any>(0);
    const [cmDayField, setCmDayField] = useState<any>(0);
    const [voDateField, setVoDateField] = useState<any>(null);
    const [cmDateField, setCmDateField] = useState<any>(null);

    const [voDayError, setVoDayError] = useState<any>(false);
    const [cmDayError, setCmDayError] = useState<any>(false);
    const [voDateError, setVoDateError] = useState<any>(false);
    const [cmDateError, setCmDateError] = useState<any>(false);
    const [cmMinDate, setCmMinDate] = useState<any>(new Date());
    const resetFields = () => {
        setVoDayField(0);
        setCmDayField(0);
        setVoDateField(null);
        setCmDateField(null);
        setVoDayError(false);
        setCmDayError(false);
        setVoDateError(false);
        setCmDateError(false);
    }
    const handleClose = () => {
        resetFields();
        setSelectedOption(null);
        onCloseFunc();
    }
    const handleSelectChange = (e) => {
        setSelectedOption(e);
        resetFields();
        if(e.label === 'Days') {
            setIsDaySection(true);
        }
        else {
            setIsDaySection(false);
        }
    };
    const getDropdownValues = async () => {
        if(userRole === 'CharteringManager'){
            const response = await getAPI(`InitialOffer/GetLiftSubRequestDateFormats`);
            setDropdownValues(response);
            if (response.length > 0) {
                setSelectedOption({ value: response[0].value, label: response[0].label });
                if(response[0].label === 'Days'){
                    setIsDaySection(true);
                }
            }
        }
        setIsLoading(false);
    }
    const handleDateDayChanges = (input, type) => {
        if(selectedOption?.label === 'Days') {
            if(type === 'VesselOwner'){
                if(input < 1 || input > 999){
                    setVoDayError(true);
                }
                setVoDayField(input);
            } else if(type === 'CharteringManager'){
                if(input < 1 || input > 999){
                    setCmDayError(true);
                }
                setCmDayField(input);
            }
        }else {
            if(type === 'VesselOwner'){
                setCmDateField(null);
                setVoDateField(input);
            } else if(type === 'CharteringManager'){
                setCmDateField(input);
            }
        }
    }
    const handleSubmit = () => {
        if(selectedOption?.label == "Date" && (voDateField == "" || voDateField == null)){
            setVoDateError(true);
            return;
        }
        if(selectedOption?.label == "Date" && (cmDateField == "" || cmDateField == null)){
            setCmDateError(true);
            return;
        }
        if(selectedOption?.label == "Days" && (voDayField < 1 || voDayField == null || voDayField > 999)){
            setVoDayError(true);
            return;
        }
        if(selectedOption?.label == "Days" && (cmDayField < 1 || cmDayField == null || cmDayField > 999)){
            setCmDayError(true);
            return;
        }
        if (moment(cmDateField).isSameOrBefore(voDateField)) {
            setCmDateError(true);
            return;
        }
        const payload = {
            formatTypeId: selectedOption?.value,
            voLiftSubRequestDays: voDayField,
            cmLiftSubRequestDays: cmDayField,
            voLiftSubRequestDate: selectedOption?.label == "Date" ? moment(voDateField).format("YYYY-MM-DDTHH:mm") : voDateField,
            cmLiftSubRequestDate: selectedOption?.label == "Date" ? moment(cmDateField).format("YYYY-MM-DDTHH:mm") : cmDateField,
        }
        console.log(payload , "payload");
        onSubmitFunc(payload);
    }
    useEffect(() => {
        resetFields();
        setIsLoading(true);
        getDropdownValues();
    }, [isModalOpen]);
    useEffect(() => {
        if (voDateField !== null) {
            setCmMinDate(voDateField);
        }
    }, [voDateField]);
    useEffect(() => {
        const getUserRoleAsync = async () => {
          const userRole = await getUserRole();
          setUserRole(userRole);
        };
        getUserRoleAsync();
      }, []);
    return (
        <McModal
            width="780px"
            height="auto"
            className="issue-recap-selection-modal"
            data-testid="issue-recap-selection-modal"
            heading="Please select liftsub date/days for chartering manager and vessel owner."
            open={isModalOpen}
            backdropcloseactiondisabled
        >
            <div className="body-div">
                <Select
                    name="liftsub-type"
                    className="liftsub-type-select"
                    options={dropdownValues}
                    data-testid="liftsub-type"
                    value={selectedOption}
                    onChange={(e) => handleSelectChange(e)}
                />
                {isLoading && <McLoadingIndicator></McLoadingIndicator>}
                <div className="row liftsub-div-section">
                    <div className="col-md-6">
                        <div className="header-div">
                            <span className="header-title">Vessel Owner</span>
                        </div>
                        <div className="body-content owner-body">
                            <div className={`${isDaySection ? 'show-div' : 'hide-div'}`}>
                                <input 
                                    type="number" 
                                    min={1} 
                                    className="form-control" 
                                    placeholder="Select no of days" 
                                    onChange={(e) => {
                                        setVoDayError(false);
                                        handleDateDayChanges(e.target.value, "VesselOwner")
                                        }
                                    } 
                                />
                                {voDayError && (<span className="error-msg">Please enter a valid number of days</span>)}
                            </div>
                            <div className={`${!isDaySection ? 'show-div' : 'hide-div'}`}>
                                <DateTimePicker
                                    value={voDateField}
                                    onChange={(date: any) => {
                                        setVoDateError(false);
                                        handleDateDayChanges(date ? date : "", "VesselOwner");
                                    }}
                                    className="inputText owner-date-picker"
                                    format="dd/MM/yyyy HH:mm"
                                    minDate={new Date()}
                                />
                                {voDateError && (<span className="error-msg">Please enter date</span>)}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="header-div">
                            <span className="header-title">Chartering Manager</span>
                        </div>
                        <div className="body-content manager-body">
                            <div className={`${isDaySection ? 'show-div' : 'hide-div'}`}>
                                <input 
                                    type="number" 
                                    min={1} 
                                    className="form-control" 
                                    placeholder="Select no of days" 
                                    onChange={(e) => {
                                            setCmDayError(false);
                                            handleDateDayChanges(e.target.value, "CharteringManager")
                                        }
                                    } 
                                />
                                {cmDayError && (<span className="error-msg">Please enter a valid number of days</span>)}
                            </div>
                            <div className={`${!isDaySection ? 'show-div' : 'hide-div'}`}>
                                <DateTimePicker
                                    value={cmDateField}
                                    onChange={(date: any) => {
                                        setCmDateError(false);
                                        handleDateDayChanges(date ? date : "", "CharteringManager");
                                    }}
                                    className="inputText owner-date-picker"
                                    format="dd/MM/yyyy HH:mm"
                                    disabled={voDateField ? false : true}
                                     data-testid="cm-date-picker"
                                    minDate={cmMinDate}
                                />
                                {cmDateError && moment(cmDateField).isSameOrBefore(voDateField) 
                                    ? (<span className="error-msg">Date should be greater than Vessel Owner's date</span>) 
                                    : cmDateError && (<span className="error-msg">Please enter date</span>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <McButton
                slot="primaryAction"
                variant="primary"
                fit="small"
                label={"Submit Initial Recap"}
                data-testid="submit-button"
                onClick={handleSubmit}
            ></McButton>
            <McButton
                slot="secondaryAction"
                variant="secondary"
                fit="small"
                label={"Cancel"}
                data-testid="cancel-button"
                onClick={handleClose}
            ></McButton>
        </McModal>
    )
}

export default IssueRecapSelectionModal;